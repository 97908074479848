import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
// import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import ContactSection from "../components/contactSection"
import DescriptionSection from "../components/descriptionSection"

import "./lokalizacja.css"

const LokalizacjaPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Lokalizacja" data={data.header} />
      </AnimationWrapper>
      {data.page.frontmatter.description_sections[0] &&
        <section id="lokalizacja-desc" className="page-section">
          {data.page.frontmatter.description_sections.map((description_section) => (
              <DescriptionSection location="lokalizacja" data={description_section} />
          ))}
        </section>
      }

      {data.page.frontmatter.contact_section &&
        <section id="contact-data" className="page-section">
          <ContactSection data={data.page.frontmatter.contact_section} />
        </section>
      }

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "lokalizacja" }, type: { eq: "page" } }) {
      frontmatter{
        title
        description_sections{
          title
          title_visible
          subtitle
          align
          text
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        contact_section{
          title
          description
          type
          submit_button_style
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/lokalizacja-header.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default LokalizacjaPage
